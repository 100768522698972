/**
 * Created by ZengFanhui on 2021/1/10 14:23
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取屠宰场列表
 * @param type -1:全部
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetSlaughterhouseList (pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    pageIndex,
    pageSize
  }
  return doHttp('/trace/slaughterhouse/list.action', HTTP_TYPE.GET, params)
}

/**
 * 编辑屠宰场
 * @param params
 * @returns {*}
 */
export function apiEditSlaughterhouse(params) {
  return doHttp('/trace/slaughterhouse/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/trace/slaughterhouse/delete.action', HTTP_TYPE.GET, params)
}
